import * as React from "react"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { useForm } from "react-hook-form"

export default function ContactForm() {

    const { t } = useTranslation()

    const { register, handleSubmit, reset, formState: { errors } } = useForm()
    const url = "https://new.luisgeneralcontractor.com/php/process.php"

    const onSubmit = (data,e) => {

        const btnSubmit = document.getElementById('formSubmit')
        btnSubmit.disabled = true

        fetch(url, {
            method: `POST`,
            body: JSON.stringify(data),
            headers: new Headers(),
        })
        .then(res => res.json())
        .then(body => {
            console.log(`response from API:`, body)

            let alertBox     = document.querySelector('.alert-form')
            let alertBoxText = document.querySelector('.alert-text')
            if ( body.respuesta === 1 ) {
                e.target.reset();
                alertBox.classList.add("alert-success","show")
            } else {
                alertBox.classList.add("alert-warning","show")
            }
            alertBoxText.innerHTML = body.texto_respuesta
            btnSubmit.disabled = false

        })
        // alert(JSON.stringify(data));
    }

    // console.log({ errors })

    return(

        <section id="ContactForm" className="py-5 bg-light">

            <div className="container">

                <div className="row">

                    <div className="col-lg-6 offset-lg-3">

                        <div className="alert alert-form alert-dismissible fade">
                            <div className="alert-text"></div>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} className="form-ContactForm">
                            <label htmlFor="name" className="form-label"><Trans>FORM Name</Trans> <span>*</span></label>
                            <input
                                id="name"
                                type="text"
                                className="form-control mb-3"
                                placeholder={t('FORM Name Placeholder')}
                                {...register("Name", { required: true, maxLength: 80 })}
                            />
                            {errors.Name && <p>{t("Error Name")}</p>}

                            <label htmlFor="email" className="form-label"><Trans>FORM Email</Trans> <span>*</span></label>
                            <input
                                id="email"
                                type="email"
                                className="form-control mb-3"
                                placeholder={t('FORM Email Placeholder')}
                                {...register("Email", { required: true, pattern: /^\S+@\S+$/i })}
                            />
                            {errors.Name && <p>{t("Error Email")}</p>}

                            <label htmlFor="message" className="form-label"><Trans>FORM Message</Trans></label>
                            <textarea
                                id="message"
                                className="form-control mb-3"
                                placeholder={t('FORM Message Placeholder')}
                                {...register("Message", {})}
                            />

                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <input type="hidden" name="Send" value="1" {...register("Send", {})} />
                                <input type="submit" id="formSubmit" className="btn btn-dark btn-block" />
                            </div>

                        </form>


                    </div>

                </div>

            </div>

        </section>

    )

} 
